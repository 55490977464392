// extracted by mini-css-extract-plugin
export var base = "AuthorPage-module--base--1REeG";
export var rightAlignedContainer = "AuthorPage-module--rightAlignedContainer--1_eVC";
export var rightAlignedContainerSmall = "AuthorPage-module--rightAlignedContainerSmall--BGdLp";
export var leftAlignedContainer = "AuthorPage-module--leftAlignedContainer--3ZGVS";
export var blogContainer = "AuthorPage-module--blogContainer--2blKY AuthorPage-module--base--1REeG";
export var container = "AuthorPage-module--container--2mwLL AuthorPage-module--base--1REeG";
export var largeContainer = "AuthorPage-module--largeContainer--3-X53 AuthorPage-module--base--1REeG";
export var mobileSmallPadding = "AuthorPage-module--mobileSmallPadding--1VstV";
export var fullWidthMobile = "AuthorPage-module--fullWidthMobile--3v42B";
export var gridWithSidebar = "AuthorPage-module--gridWithSidebar--2f9zs";
export var noMaxWidth = "AuthorPage-module--noMaxWidth--2_3Ly";
export var wrap = "AuthorPage-module--wrap--13t-8 AuthorPage-module--blogContainer--2blKY AuthorPage-module--base--1REeG AuthorPage-module--gridWithSidebar--2f9zs";
export var headerWrap = "AuthorPage-module--headerWrap--1PRuu";
export var authorArticles = "AuthorPage-module--authorArticles--1YuMT";
export var recentPosts = "AuthorPage-module--recentPosts--2Knyj";
export var sidebar = "AuthorPage-module--sidebar--3S1vX";
export var authorHeader = "AuthorPage-module--authorHeader--2-dIn";
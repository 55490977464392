import {
  ArticlePreview,
  AuthorHeader,
  CuratedArticlesList,
  SectionTitle
} from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import MediaQuery from 'react-responsive';

import AnimotoBlogLayout from '../components/AnimotoBlogLayout';
import BlogPageNavigation from '../components/BlogPageNavigation';
import RecentPostsSidebar from '../components/RecentPostsSidebar';
import { generatePaginatedUrls } from '../utils/paginationUtils';
import {
  authorArticles,
  authorHeader,
  headerWrap,
  recentPosts as recentPostsClass,
  sidebar,
  wrap
} from './AuthorPage.module.css';

export default function AuthorPage({ data, pageContext }) {
  const {
    contentfulBlogAuthor  : author,
    allContentfulBlogPost : posts,
    recentPosts
  } = data;
  const {
    noIndex,
    pathPrefix,
    pageTitle
  } = pageContext;
  const SEOInfo = {
    author,
    canonicalSlug : '/blog',
    description   : author.description && author.description.description,
    image         : author.photo && author.photo.file.url,
    noIndex,
    slug          : `blog/author/${author.slug}`,
    title         : author.name,
    type          : 'blogAuthor'
  };
  const {
    nextUrl,
    previousUrl,
    currentPage,
    hasNextPage,
    hasPreviousPage
  } = generatePaginatedUrls({ pathPrefix, ...posts.pageInfo });

  const articles = posts.edges.map((post) => {
    const node = post.node ? post.node : post;
    const {
      featuredImage,
      fields,
      id,
      mainCategory,
      publishedOn,
      title,
      updatedAt
    } = node;

    return (
      <ArticlePreview
        key={id}
        category={(mainCategory && mainCategory.name) || ''}
        categoryUrl={(mainCategory && mainCategory.fields && `/blog/category/${mainCategory.fields.categorySlug}`) || ''}
        imageSrcFluid={featuredImage && featuredImage.fluid}
        isAlignedLeft
        postUrl={`/blog/${fields.postSlug}`}
        publishDate={publishedOn || updatedAt}
        title={title}
      />
    );
  });

  return (
    <AnimotoBlogLayout SEOInfo={SEOInfo}>
      <div className={headerWrap}>
        {!hasPreviousPage && (
          <AuthorHeader
            author={author.name}
            className={authorHeader}
            description={author.description && author.description.description}
            image={author.photo && author.photo.file.url}
            title="Most recent articles by:"
            twitter={author.twitterHandle}
            website={author.url}
          />
        )}
        {hasPreviousPage && (
          <SectionTitle>{pageTitle}</SectionTitle>
        )}
      </div>
      <div className={wrap}>
        <div className={authorArticles}>
          {articles}
          {
            (hasNextPage || hasPreviousPage)
            && <BlogPageNavigation currentPage={currentPage} nextUrl={nextUrl} previousUrl={previousUrl} />
          }
        </div>
        <MediaQuery maxWidth={767}>
          <CuratedArticlesList
            className={recentPostsClass}
            title="Recent Posts"
          >
            {recentPosts.edges.map((post) => {
              const {
                id,
                title,
                featuredImage,
                mainCategory,
                fields
              } = post.node;

              return (
                <ArticlePreview
                  key={id}
                  category={(mainCategory && mainCategory.name) || ''}
                  categoryUrl={(mainCategory && `/blog/category/${mainCategory.slug}`) || ''}
                  imageSrcFluid={featuredImage && featuredImage.fluid}
                  postUrl={`/blog/${fields.postSlug}`}
                  title={title}
                />
              );
            })}
          </CuratedArticlesList>
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <RecentPostsSidebar className={sidebar} />
        </MediaQuery>
      </div>
    </AnimotoBlogLayout>
  );
}

export const pageQuery = graphql`
  query AuthorPageInformation($id: String!, $limit: Int!, $skip: Int!, $posts: [String]) {
    contentfulBlogAuthor(id: {eq:$id}) {
      description {
        description
      }
      name
      photo {
        file {
          url
        }
      }
      slug
      twitterHandle
      url
    }
    allContentfulBlogPost(
      limit: $limit,
      skip: $skip,
      filter: {
        id            : { in : $posts },
        noIndexOnBlog : { ne : true }
      },
      sort: {fields : publishedOn, order :DESC}
    ) {
      pageInfo {
        currentPage
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          id
          title
          publishedOn
          updatedAt
          featuredImage {
            fluid(maxWidth: 670, maxHeight: 447) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          mainCategory {
            name
            fields {
              categorySlug
            }
          }
          fields {
            postSlug
          }
        }
      }
    }
    recentPosts: allContentfulBlogPost(limit: 3, sort: {fields : [publishedOn], order :DESC}) {
      edges {
        node {
          id
          title
          featuredImage {
            fluid(maxWidth: 670, maxHeight: 447) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          fields {
            postSlug
          }
          mainCategory {
            name
            slug
          }
        }
      }
    }
  }
`;

AuthorPage.propTypes = {
  data : PropTypes.shape({
    allContentfulBlogPost : PropTypes.shape({
      edges : PropTypes.arrayOf(
        PropTypes.shape({
          node : PropTypes.shape({
            featuredImage : PropTypes.shape({
              fixed : PropTypes.shape({
                src : PropTypes.string
              })
            }),
            fields : PropTypes.shape({
              postSlug : PropTypes.string
            }),
            id           : PropTypes.string,
            mainCategory : PropTypes.shape({
              fields : PropTypes.shape({
                categorySlug : PropTypes.string
              }),
              name : PropTypes.string
            }),
            publishedOn         : PropTypes.string,
            retinaFeaturedImage : PropTypes.shape({
              fixed : PropTypes.shape({
                src : PropTypes.string
              })
            }),
            title     : PropTypes.string,
            updatedAt : PropTypes.string
          })
        })
      ),
      pageInfo : PropTypes.shape({
        hasNextPage : PropTypes.bool
      })
    }),
    contentfulBlogAuthor : PropTypes.shape({
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      name : PropTypes.string
    }),
    recentPosts : PropTypes.shape({
      edges : PropTypes.arrayOf(
        PropTypes.shape({
          node : PropTypes.shape({
            featuredImage : PropTypes.shape({
              fluid : PropTypes.shape({
                aspectRatio : PropTypes.number,
                sizes       : PropTypes.string,
                src         : PropTypes.string,
                srcSet      : PropTypes.string,
                srcSetWebp  : PropTypes.string,
                srcWebp     : PropTypes.string
              })
            }),
            id           : PropTypes.string,
            mainCategory : PropTypes.shape({
              name : PropTypes.string,
              slug : PropTypes.string
            }),
            title : PropTypes.string
          })
        })
      )
    })
  }).isRequired,
  pageContext : PropTypes.shape({
    index      : PropTypes.number,
    noIndex    : PropTypes.bool,
    pageTitle  : PropTypes.string,
    pathPrefix : PropTypes.string
  }).isRequired
};
